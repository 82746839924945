import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import {Grid} from '@material-ui/core'
import './App.css';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
const hdr_card = (
  <React.Fragment>
 <Grid container alignItems="center" spacing={4}>
  <Grid item xs={1}>
    <CardMedia
    component="img"
    sx={{width: 25}}
    image="logo_144x144.png"
    alt="Mivera"
  />
  </Grid>
  <Grid item xs={10}>
    <CardContent>
    <Typography gutterBottom variant="h5" component="div">
      Mivera Jobs
    </Typography>
  </CardContent>
  </Grid>
</Grid>
    </React.Fragment>
    );
const intro_card = ( 
  <React.Fragment>
     <CardContent>
   <Typography variant="h5" sx={{ mb: 1.5 }} style={{align:"center"}} component="div" color="text.secondary">
     Who We Are
    </Typography>
    <Typography variant="body2">
        Mivera is an early stage startup developing tools to help our parents and grandparents as they age.
     <br /> <br/>
    </Typography>
    </CardContent>
    </React.Fragment>
    );

const card = (
  <React.Fragment>
    <CardContent>
      <Typography  variant="h6" sx={{mb: 1.0}} style={{align:"center"}} component="div">
        Student Intern
      </Typography> <br/>  <br/>
      <Typography sx={{ mb: 1.0 }} color="text.secondary">
      Mivera Technologies is seeking a IBM Watson Chatbot Developer - 100% Fully Remote <br/>
      </Typography>
      <Typography variant="body2">
      The Developer will work with our team and develop AI solutions that utilize IBM Watson Services and/or other technologies/frameworks as directed. <br/>
     </Typography>
      <Typography sx={{ mb: 1.0 }} color="text.secondary">Responsibilities: <br /></Typography>
      <Typography variant="body2">
{bull}Enhance and support an existing AI/Chatbot application<br />
{bull}Perform root-cause analysis to identify and fix production application(s) issues and bugs.
</Typography>

<Typography sx={{ mb: 1.0 }} color="text.secondary">Requirements:<br /></Typography>
<Typography variant="body2">
{bull}High School senior or Bachelor’s degree candidate in computer science, electronics engineering.<br/>
{bull}Knowledge of Javascript.<br/>
{bull}Knowledge of Chatbot basics preferred, but not required. We will train you.<br/>
{bull}Must be able to commit 10 hours a week for this project for 3 months.<br/>
</Typography>
<Typography sx={{ mb: 1.0 }} color="text.secondary">
What you will get:
</Typography>
<Typography variant="body2">
{bull}Hands-on training and experience on creating a production ready chatbot.<br/>
{bull} The Position is unpaid, but there is a performance bonus of up to $500  at the end of 3 months.<br/>
{bull}Potential to convert to a paid position.       
<br />
</Typography>
    </CardContent>
    <CardActions>
      <Button omClicksize="small">Please email info@miveratech.com with your resume</Button>
    </CardActions>
  </React.Fragment>
);

function App() {
  return (
  <Card sx={{display: 'flex'}}>  
   <Box sx={{alignItems: 'center', pl:2, pb:2}}>
    <Card variant="outlined">{hdr_card}</Card>
   </Box>
   <Box sx={{display:'flex',alignItems: 'center' , pl:2, pb:2}}>
     <Card sx={{display: 'flex'}} variant="outlined">{intro_card}</Card>
   </Box>
   <Box sx={{ display: 'flex', alignItems: 'center' , pl: 2, pb:2}}>
     <Card sx={{display: 'flex'}} variant="outlined">{card}</Card>
   </Box>
   </Card>
  );
}

export default App;
